<template>
<div id="pagecontainer">
  <div id="header">
    <h1>kolassa.us</h1>
    <h2>a family website</h2>
  </div>
  <div id="nav">
    <ul>
      <li><a href="">Home</a></li>
    </ul>
  </div>
  <div id="content">
    <p>This page serves as a placeholder for a collection of family websites and has no content itself.</p>
    <p>If someone has a suggestion of what should be here, please contact me and let me know.</p>
  </div>
  <div id="footer">
  </div>
</div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
a {color: #3366aa; text-decoration: none; font-weight: bold;}
a:hover {color: #6699ff; text-decoration: none; font-weight: bold;}

body
{
}

#pagecontainer
{
  margin: 0px auto 0px auto;
  padding: 0px 0px 0px 0px;
  width: 892px;
  /*border: 1px solid black;*/
}

#header
{
}
#header h1
{
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  text-align: right;
  letter-spacing: 8px;
  font-size: 36px;
}
#header h2
{
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  text-align: right;
  letter-spacing: 4px;
  font-size: 24px;
}

#nav
{
  margin: 10px 0px 10px 0px;
  padding: 0px 0px 0px 0px;
  height: 30px;
  width: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
#nav ul
{
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
#nav ul li
{
  margin: 0px 0px 0px 0px;
  padding: 5px 10px 5px 10px;
  float: left;
  display: list-item;
  list-style: none;
}

#content
{
  margin: 0px 0px 0px 0px;
  padding: 0px 10px 0px 10px;
}
#content p
{
  margin: 20px 0px 20px 0px;
  padding: 0px 0px 0px 0px;
}

#footer
{
  margin: 10px auto 10px auto;
  padding: 0px 0px 0px 0px;
  border-top: 1px solid black;
}
#footer p
{
  margin: 0px 0px 0px 0px;
  padding: 5px 5px 5px 5px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
}
</style>
